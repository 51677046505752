import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { v4 as uuidv4 } from 'uuid';
import './global.css';
import './fonts.css';
import './mobile.css';

import Navbar from './components/Navbar';
import Sidebar from './new/Sidebar';
import GenericTemplate from './new/links/GenericTemplate';
import Faq from './new/Faq';

function App() {
  const dummyData = [
    {
      title: "Recently Used",
      links: [
        { id: `link-${uuidv4()}`, name: "Google", url: "https://www.google.com" },
        { id: `link-${uuidv4()}`, name: "ChatGPT", url: "https://www.chatgpt.com" },    
      ]
    },    
    {
      title: "⌚️ Using Now",
      links: [
        { id: `link-${uuidv4()}`, name: "NomadTube", url: "https://www.digitalnomadtube.com" },
        { id: `link-${uuidv4()}`, name: "NoteWorkspace", url: "https://www.noteworkspace.com" },
        { id: `link-${uuidv4()}`, name: "RamenLinks", url: "https://www.ramenlinks.com" },
        { id: `link-${uuidv4()}`, name: "ChatGPT", url: "https://www.chatgpt.com" },
        { id: `link-${uuidv4()}`, name: "Vercel", url: "https://vercel.com/" },
        { id: `link-${uuidv4()}`, name: "Squarespace Domains", url: "https://domains.squarespace.com/" },        
      ]
    },    
    {
      title: "💯 Favorites",
      links: [
        { id: `link-${uuidv4()}`, name: "NomadTube", url: "https://www.digitalnomadtube.com" },
        { id: `link-${uuidv4()}`, name: "NoteWorkspace", url: "https://www.noteworkspace.com" },
        { id: `link-${uuidv4()}`, name: "RamenLinks", url: "https://www.ramenlinks.com" },
        { id: `link-${uuidv4()}`, name: "Vercel", url: "https://vercel.com/" },

        { id: `link-${uuidv4()}`, name: "Google", url: "https://www.google.com" },
        { id: `link-${uuidv4()}`, name: "Gmail", url: "https://mail.google.com/mail/u/0/#inbox" },
        { id: `link-${uuidv4()}`, name: "Google Maps", url: "https://www.google.com/maps" },
        { id: `link-${uuidv4()}`, name: "ChatGPT", url: "https://www.chatgpt.com" },
        { id: `link-${uuidv4()}`, name: "YouTube", url: "https://www.youtube.com" },
        { id: `link-${uuidv4()}`, name: "Notion", url: "https://www.notion.so" },
        { id: `link-${uuidv4()}`, name: "Netflix", url: "https://www.netflix.com" },
        { id: `link-${uuidv4()}`, name: "LinkedIn", url: "https://www.linkedin.com" },
        { id: `link-${uuidv4()}`, name: "Reddit", url: "https://www.reddit.com" },
        { id: `link-${uuidv4()}`, name: "Spotify", url: "https://www.spotify.com" },
        { id: `link-${uuidv4()}`, name: "Pluto TV", url: "https://pluto.tv/" },
      ]
    },
    {
      title: "🔗 Top Links",
      links: [
        { id: `link-${uuidv4()}`, name: "Google", url: "https://www.google.com" },
        { id: `link-${uuidv4()}`, name: "ChatGPT", url: "https://www.chatgpt.com" },
        { id: `link-${uuidv4()}`, name: "YouTube", url: "https://www.youtube.com" },
        { id: `link-${uuidv4()}`, name: "Notion", url: "https://www.notion.so" },
        { id: `link-${uuidv4()}`, name: "Netflix", url: "https://www.netflix.com" },
        { id: `link-${uuidv4()}`, name: "LinkedIn", url: "https://www.linkedin.com" },
        { id: `link-${uuidv4()}`, name: "Reddit", url: "https://www.reddit.com" },
        { id: `link-${uuidv4()}`, name: "Canva", url: "https://www.canva.com" },
        { id: `link-${uuidv4()}`, name: "Product Hunt", url: "https://www.producthunt.com" },

        { id: `link-${uuidv4()}`, name: "Github", url: "https://www.github.com" },
        { id: `link-${uuidv4()}`, name: "Spotify", url: "https://www.spotify.com" },
        { id: `link-${uuidv4()}`, name: "Wikipedia", url: "https://www.wikipedia.org" },
        { id: `link-${uuidv4()}`, name: "Instagram", url: "https://www.instagram.com" },
        { id: `link-${uuidv4()}`, name: "X", url: "https://www.x.com" }
      ]
    },
    {
      title: "📸 Social Media",
      links: [
        { id: `link-${uuidv4()}`, name: "Instagram", url: "https://www.instagram.com" },
        { id: `link-${uuidv4()}`, name: "TikTok", url: "https://www.tiktok.com" },
        { id: `link-${uuidv4()}`, name: "X", url: "https://www.x.com" },
        { id: `link-${uuidv4()}`, name: "Reddit", url: "https://www.reddit.com" },
        { id: `link-${uuidv4()}`, name: "Facebook", url: "https://www.facebook.com" },
        { id: `link-${uuidv4()}`, name: "Snapchat", url: "https://www.snapchat.com" },
        { id: `link-${uuidv4()}`, name: "LinkedIn", url: "https://www.linkedin.com" },
        { id: `link-${uuidv4()}`, name: "Pinterest", url: "https://www.pinterest.com" },
        { id: `link-${uuidv4()}`, name: "Twitch", url: "https://www.twitch.tv" },
      ]
    },
    {
      title: "🏝️ Travel",
      links: [
        { id: `link-${uuidv4()}`, name: "Nomadlist", url: "https://www.nomadlist.com" },
        { id: `link-${uuidv4()}`, name: "Hoodmaps", url: "https://www.hoodmaps.com" },
        { id: `link-${uuidv4()}`, name: "Google Flights", url: "https://www.google.com/travel/flights" },
        { id: `link-${uuidv4()}`, name: "Google Maps", url: "https://www.google.com/maps" },

        { id: `link-${uuidv4()}`, name: "AirBNB", url: "https://www.airbnb.com" },
        { id: `link-${uuidv4()}`, name: "Agoda", url: "https://www.agoda.com" },
        { id: `link-${uuidv4()}`, name: "Booking.com", url: "https://www.booking.com" },
        { id: `link-${uuidv4()}`, name: "HostelWorld", url: "https://www.hostelworld.com/" },
      ]
    },
    {
      title: "🖥️ Streaming",
      links: [
        { id: `link-${uuidv4()}`, name: "Netflix", url: "https://www.netflix.com" },
        { id: `link-${uuidv4()}`, name: "Hulu", url: "https://www.hulu.com" },
        { id: `link-${uuidv4()}`, name: "HBO MAX", url: "https://www.hbomax.com" },
        { id: `link-${uuidv4()}`, name: "Pluto TV", url: "https://pluto.tv/" },
        { id: `link-${uuidv4()}`, name: "Amazon Prime Video", url: "https://www.primevideo.com" },
        { id: `link-${uuidv4()}`, name: "Disney+", url: "https://www.disneyplus.com" },
        { id: `link-${uuidv4()}`, name: "Peacock", url: "https://www.peacocktv.com" },
        { id: `link-${uuidv4()}`, name: "Apple TV+", url: "https://tv.apple.com" },
      ]
    },
    {
      title: "💻 Coding",
      links: [
        { id: `link-${uuidv4()}`, name: "Github", url: "https://www.github.com" },
        { id: `link-${uuidv4()}`, name: "Vercel", url: "https://vercel.com/" },
        { id: `link-${uuidv4()}`, name: "shadcn/ui", url: "https://ui.shadcn.com/" },
        { id: `link-${uuidv4()}`, name: "NBRTLSM", url: "https://www.neobrutalism.dev/" },
        { id: `link-${uuidv4()}`, name: "Lucide", url: "https://www.lucide.dev" },
        { id: `link-${uuidv4()}`, name: "Squarespace Domains", url: "https://domains.squarespace.com/" },
        { id: `link-${uuidv4()}`, name: "Stack Overflow", url: "https://stackoverflow.com" },
        { id: `link-${uuidv4()}`, name: "CodePen", url: "https://codepen.io" },
      ]
    },
    {
      title: "🧠 AI",
      links: [
        { id: `link-${uuidv4()}`, name: "ChatGPT", url: "https://www.chatgpt.com" },
        { id: `link-${uuidv4()}`, name: "Midjourney", url: "https://www.midjourney.com" },
        { id: `link-${uuidv4()}`, name: "Claude", url: "https://claude.ai/new" },
        { id: `link-${uuidv4()}`, name: "Perplexity", url: "https://www.perplexity.ai/" },
        { id: `link-${uuidv4()}`, name: "RunwayML", url: "https://app.runwayml.com/" },
        { id: `link-${uuidv4()}`, name: "ElevenLabs", url: "https://elevenlabs.io/" },
        { id: `link-${uuidv4()}`, name: "Luma Dream Machine", url: "https://www.LumaLabs.com" },
        { id: `link-${uuidv4()}`, name: "Stable Assistant", url: "https://assistant.stability.ai/" },
        { id: `link-${uuidv4()}`, name: "Gamma", url: "https://gamma.app/" },
        { id: `link-${uuidv4()}`, name: "Captions AI", url: "https://www.captions.ai/" },
        { id: `link-${uuidv4()}`, name: "Synthesia", url: "https://www.synthesia.io" },
        { id: `link-${uuidv4()}`, name: "Copy.ai", url: "https://www.copy.ai" },
        { id: `link-${uuidv4()}`, name: "Jasper", url: "https://www.jasper.ai" },
      ]
    },
    {
      title: "➕ Extra",
      links: [
        { id: `link-${uuidv4()}`, name: "Canva", url: "https://www.canva.com" },
        { id: `link-${uuidv4()}`, name: "Figma", url: "https://www.figma.com" },
        { id: `link-${uuidv4()}`, name: "Figjam", url: "https://www.figma.com/figjam/" },
        { id: `link-${uuidv4()}`, name: "Wikipedia", url: "https://www.wikipedia.org/" },
      ]
    }
  ];

  const [openSidebar, setOpenSidebar] = useState(false);

  const [linkSets, setLinkSets] = useState(() => {
    const storedData = JSON.parse(localStorage.getItem("RamenLinks"));
    if (Array.isArray(storedData)) {
      return storedData;
    } else {
      localStorage.setItem("RamenLinks", JSON.stringify(dummyData));
      return dummyData;
    }
  });

  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    localStorage.setItem("RamenLinks", JSON.stringify(linkSets));
  }, [linkSets]);

    const handleNewSectionSubmit = (e) => {
      e.preventDefault();
      if (newTitle.trim()) {
          const newSection = {
              title: newTitle,
              links: [],
          };
          // Add the new section at the top of the list
          setLinkSets([newSection, ...linkSets]);
          setNewTitle("");
      }
  };

  const updateLinks = (sectionTitle, updatedLinks) => {
    const updatedLinkSets = linkSets.map(set =>
      set.title === sectionTitle ? { ...set, links: updatedLinks } : set
    );
    setLinkSets(updatedLinkSets);
  };
  

  const handleSectionDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = linkSets.findIndex(set => set.title === active.id);
      const newIndex = linkSets.findIndex(set => set.title === over.id);
      const newLinkSets = arrayMove(linkSets, oldIndex, newIndex);
      setLinkSets(newLinkSets);
    }
  };

  const handleDeleteSection = (title) => {
    const confirmed = window.confirm("Are you sure you want to delete this section and all its links?");
    if (confirmed) {
      const updatedLinkSets = linkSets.filter(set => set.title !== title);
      setLinkSets(updatedLinkSets);
    }
  };
  

  const toggleSidebar = () => setOpenSidebar(!openSidebar);
  const closeSidebar = () => setOpenSidebar(false);

  // SortableSection component to add drag functionality with a handle
  const SortableSection = ({ id, title, links }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
    const style = {
      transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
      transition,
      zIndex: isDragging ? 10 : 1,
    };

    return (
      <>
      <div ref={setNodeRef} style={style} className="sortable-section">
        <div className="drag-handle section" {...listeners} {...attributes}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="19" r="1" />
            <circle cx="19" cy="5" r="1" />
            <circle cx="19" cy="12" r="1" />
            <circle cx="19" cy="19" r="1" />
            <circle cx="5" cy="5" r="1" />
            <circle cx="5" cy="12" r="1" />
            <circle cx="5" cy="19" r="1" />
          </svg>
        </div>

        <GenericTemplate key={title} defaultLinks={links} title={title} handleDeleteSection={handleDeleteSection} updateLinks={updateLinks}/>
      </div>
      <div className='spacing'></div>
      </>
    );
  };

  return (
    <Router>
      <div className='d-flex flex-col'>
        <Navbar toggleSidebar={toggleSidebar} />
      </div>
      <div className="app d-flex">

        <div className="content d-flex flex-col w-100 relative">
          <form onSubmit={handleNewSectionSubmit} className="add-section-form">
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="New Section Title"
              required
              className='edit-title-input'
            />
            <button type="submit" className='edit-title-input add-new-section-btn desktop-only'>Add New Section</button>
            <button type="submit" className='edit-title-input add-new-section-btn mobile-tablet-only'>Add</button>
          </form>

          <DndContext collisionDetection={closestCenter} onDragEnd={handleSectionDragEnd} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={linkSets.map(set => set.title)} strategy={rectSortingStrategy}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      {linkSets.map((set) => (
                        <SortableSection key={set.title} id={set.title} title={set.title} links={set.links} />
                      ))}
                    </>
                  }
                />
                <Route path="/faq" element={<Faq />} />
              </Routes>
            </SortableContext>
          </DndContext>
        </div>
        <Sidebar openSidebar={openSidebar} closeSidebar={closeSidebar} />
      </div>
    </Router>
  );
}

export default App;
