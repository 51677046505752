// Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SidebarFolder from './SidebarFolder'

import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import '../components/sidebar.css';
import './styles/sidebar.css';

// Add this function to update NoteWorkspace with folderOrder
const updateNoteWorkspaceFolderOrder = (newFolderOrder) => {
  const noteWorkspace = JSON.parse(localStorage.getItem('RamenLinks')) || {};
  noteWorkspace.folderOrder = newFolderOrder;
  localStorage.setItem('RamenLinks', JSON.stringify(noteWorkspace));
};

const Folder = ({ id, children }) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="folder">
      {/* Separate drag handle */}
      <span {...listeners} className="drag-handle">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical">
          <circle cx="9" cy="12" r="1" />
          <circle cx="9" cy="5" r="1" />
          <circle cx="9" cy="19" r="1" />
          <circle cx="15" cy="12" r="1" />
          <circle cx="15" cy="5" r="1" />
          <circle cx="15" cy="19" r="1" />
        </svg>
      </span>
      {children}
    </div>
  );
};

const Sidebar = ({ folders = {}, updateFolders, setFolders, setCurrentNote, setIsPageReady, openSidebar, closeSidebar,
  homeFolderId, nowFolderId, todayFolderId, weekFolderId, todoFolderId, chatgptpromptsFolderId
}) => { // Default folders to an empty object
  const [sidebarWidth, setSidebarWidth] = useState(225);
  const [editingFolderId, setEditingFolderId] = useState(null); // Track which folder is being renamed
  const [newFolderNameInput, setNewFolderNameInput] = useState(''); // Track the new name input
  const [folderOpen, setFolderOpen] = useState({});
  const [openMenu, setOpenMenu] = useState(null); // Track which folder's popup is open
  const popupRef = useRef(null);
  const navigate = useNavigate();
  // States for renaming pages
  const [editingPageId, setEditingPageId] = useState(null);
  const [editingFolderForPageId, setEditingFolderForPageId] = useState(null);
  const [newPageNameInput, setNewPageNameInput] = useState('');
  const [folderOrder, setFolderOrder] = useState(() => {
    // Check for saved order in local storage
    const savedOrder = JSON.parse(localStorage.getItem('folderOrder'));
    return savedOrder || Object.keys(folders);
  });

  const handleFolderDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setFolderOrder((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        // Filter out folders with sidebar="permanent" as well as "folderOrder" and "home" entries
        const validOrder = newOrder.filter((id) =>
          id !== "folderOrder" &&
          id !== "home" &&
          folders[id] &&
          folders[id].sidebar !== "permanent"
        );

        updateNoteWorkspaceFolderOrder(validOrder); // Save the valid order
        return validOrder;
      });
    }
  };


  useEffect(() => {
    const savedWorkspace = JSON.parse(localStorage.getItem('RamenLinks')) || {};
    const savedOrder = savedWorkspace.folderOrder || [];

    const allFolderIds = Object.keys(folders).filter(
      (id) => id !== "folderOrder" && id !== "home"
    );

    const updatedOrder = [...new Set([...savedOrder, ...allFolderIds])];

    // Only update folderOrder if there's a difference
    if (JSON.stringify(updatedOrder) !== JSON.stringify(folderOrder)) {
      setFolderOrder(updatedOrder);
    }
  }, [folders, folderOrder]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);



  const addFolder = () => {
    const uniqueFolderId = uuidv4();
    const uniquePageId = uuidv4();
    let folderName = "New Folder";
    let count = 1;

    const isFolderNameUnique = (name) =>
      !Object.values(folders).some(folder => folder.name === name);

    while (!isFolderNameUnique(`${folderName}${count > 1 ? ` ${count}` : ''}`)) {
      count++;
    }
    folderName += count > 1 ? ` ${count}` : '';

    // Create the new folder structure
    const newFolder = {
      [uniqueFolderId]: {
        name: folderName,
        pages: {
          [uniquePageId]: {
            name: "Page 1",
            todoList: [{ name: "To Do Item 1", completed: false }],
            linkList: [
              { name: "Google", url: "https://www.google.com" },
              { name: "ChatGPT", url: "https://www.chatgpt.com" },
              { name: "YouTube", url: "https://www.youtube.com" }
            ],
            notes: [
              {
                title: "Note 1",
                content: "<h1>Title: Note 1</h1><h2>Subtitle</h2><hr/><p>Text</p> <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>"
              }
            ]
          }
        }
      }
    };

    // Retrieve the current state of NoteWorkspace from local storage and merge with the new folder
    const noteWorkspace = JSON.parse(localStorage.getItem('RamenLinks')) || {};
    const mergedFolders = {
      ...noteWorkspace,
      ...folders,
      [uniqueFolderId]: newFolder[uniqueFolderId]
    };

    // Update folders state and save to local storage
    setFolders(mergedFolders);

    localStorage.setItem('RamenLinks', JSON.stringify(mergedFolders));

    // Update the folder order
    setFolderOrder((prevOrder) => {
      const newOrder = [...prevOrder, uniqueFolderId].filter(id =>
        mergedFolders[id] && mergedFolders[id].sidebar !== "permanent"
      );
      updateNoteWorkspaceFolderOrder(newOrder);
      return newOrder;
    });
  };







  // Function to sanitize URL strings by replacing unwanted characters with hyphens
  // const sanitizeForURL = (name) => {
  //   return name
  //     .toLowerCase()
  //     .replace(/[:\/?#[\]@!$&'()*+,;=%]/g, '') // Remove URL-specific characters
  //     .replace(/[^a-z0-9]+/g, '-') // Replace remaining non-alphanumeric characters with hyphens
  //     .replace(/^-+|-+$/g, ''); // Remove leading or trailing hyphens
  // };



  const addPage = (folderId) => {
    // Ensure folder exists
    const folder = folders[folderId] || { name: "Unnamed Folder", pages: {} };

    // Ensure pages exists within the folder
    const existingPages = folder.pages || {};
    const uniquePageId = uuidv4();
    const pageName = `Page ${Object.keys(existingPages).length + 1}`;

    const newFolders = {
      ...folders,
      [folderId]: {
        ...folder,
        pages: {
          ...existingPages,
          [uniquePageId]: {
            name: pageName,
            todoList: [{ name: "To Do Item 1", completed: false }],
            linkList: [
              { name: "Google", url: "https://www.google.com" },
              { name: "ChatGPT", url: "https://www.chatgpt.com" },
              { name: "YouTube", url: "https://www.youtube.com" },
            ],
            notes: [
              {
                title: "Note 1", content: "<h1>Title: Note 1</h1><h2>Subtitle</h2><hr/><p>Text</p> <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>"
              },
            ],
          },
        },
      },
    };

    updateFolders(newFolders);
    navigate(`/${folderId}/${uniquePageId}`);
    setIsPageReady(true);
  };




  const deleteFolder = (folderId) => {
    if (window.confirm("Are you sure you want to delete this folder and all its data? This can not be recovered.")) {
      const { [folderId]: _, ...newFolders } = folders;

      updateFolders(newFolders);
      navigate('/');
      window.location.reload();
    }
  };

  const deletePage = (folderId, pageId) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      const newPages = { ...folders[folderId].pages };
      delete newPages[pageId];
      const newFolders = { ...folders, [folderId]: { ...folders[folderId], pages: newPages } };
      updateFolders(newFolders);
      navigate('/'); // Navigate to a safe route after deletion
    }
  };

  const handleRenameFolder = (folderId) => {
    if (newFolderNameInput.trim()) {
      const updatedFolders = {
        ...folders,
        [folderId]: { ...folders[folderId], name: newFolderNameInput }, // Save the unsanitized name here
      };

      updateFolders(updatedFolders);
      setEditingFolderId(null);
      setNewFolderNameInput('');
    }
  };

  const handleRenamePage = (folderId, pageId) => {
    if (newPageNameInput.trim()) {
      const updatedFolders = {
        ...folders,
        [folderId]: {
          ...folders[folderId],
          pages: {
            ...folders[folderId].pages,
            [pageId]: { ...folders[folderId].pages[pageId], name: newPageNameInput },
          },
        },
      };
      updateFolders(updatedFolders);
      setEditingPageId(null);
      setEditingFolderForPageId(null);
      setNewPageNameInput('');
      // navigate(`/${encodeURIComponent(folders[folderId].name.toLowerCase().replace(/\s+/g, '-'))}/${encodeURIComponent(newPageNameInput.toLowerCase().replace(/\s+/g, '-'))}`);
      navigate(`/${folderId}/${pageId}`);
    }
  };

  const toggleFolder = (folderId) => {
    setFolderOpen((prevState) => ({
      ...prevState,
      [folderId]: !prevState[folderId],
    }));
  };



  const handleMouseMove = (e) => {
    const newWidth = e.clientX;
    setSidebarWidth(newWidth > -1000 ? newWidth : 1200);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (e) => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        localStorage.setItem('RamenLinks', JSON.stringify(data));
        setFolders(data); // Update state with the uploaded data
      } catch (error) {
        console.error("Invalid JSON file format:", error);
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        localStorage.setItem('RamenLinks', JSON.stringify(data));
        setFolders(data); // Update state with the uploaded data
        window.location.reload();
      } catch (error) {
        console.error("Invalid JSON file format:", error);
      }
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  const togglePopup = (folderId) => {
    setOpenMenu(openMenu === folderId ? null : folderId);
  };

  const togglePopupPage = (pageId) => {
    setOpenMenu(openMenu === pageId ? null : pageId);
  };


  function downloadLocalStorage() {
    const data = JSON.parse(localStorage.getItem('RamenLinks')); // Parse the stored JSON string back to an object
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }); // Now stringify as JSON with spacing
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RamenLinks.json';
    link.click();
  }

  function deleteAllData() {
    const firstConfirmation = window.confirm("❗️ Are you sure you want to delete all your data? This cannot be recovered.");

    if (firstConfirmation) {
      const secondConfirmation = window.confirm("🗑️ This action is permanent. Do you really want to proceed with deleting all your data?");

      if (secondConfirmation) {

        const savedFolders = JSON.parse(localStorage.getItem('RamenLinks')) || {};
        if (savedFolders.accessKey && savedFolders.accessKey.key === 'paid') {

          localStorage.removeItem('RamenLinks');
          const savedFolders = JSON.parse(localStorage.getItem('RamenLinks')) || {};
          savedFolders["accessKey"] = { key: "paid", sidebar: "permanent" }
          // Save the updated `savedFolders` to `localStorage`
          localStorage.setItem('RamenLinks', JSON.stringify(savedFolders));
        } else {
          localStorage.removeItem('RamenLinks');
        }

        alert("✅ Your data has been successfully deleted");
        navigate('/');
        window.location.reload();
      }
    }
  }



  // // Find the `folderId` of the folder with `name: "Home"` and `sidebar: "permanent"`
  // const homeFolderId = Object.entries(noteWorkspace).find(
  //   ([folderId, folder]) => folder.name === "Home" && folder.sidebar === "permanent"
  // )?.[0]; // Use ?. to safely access the folderId

  // // Find the `folderId` of the folder with `name: "Now"` and `sidebar: "permanent"`
  // const nowFolderId = Object.entries(noteWorkspace).find(
  //   ([folderId, folder]) => folder.name === "Now" && folder.sidebar === "permanent"
  // )?.[0]; // Use ?. to safely access the folderId

  //   // Find the `folderId` of the folder with `name: "Today"` and `sidebar: "permanent"`
  //   const todayFolderId = Object.entries(noteWorkspace).find(
  //     ([folderId, folder]) => folder.name === "Today" && folder.sidebar === "permanent"
  //   )?.[0]; // Use ?. to safely access the folderId

  //   // Find the `folderId` of the folder with `name: "Today"` and `sidebar: "permanent"`
  //   const todoFolderId = Object.entries(noteWorkspace).find(
  //     ([folderId, folder]) => folder.name === "Todo" && folder.sidebar === "permanent"
  //   )?.[0]; // Use ?. to safely access the folderId


  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleFolderDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <div className={`sidebar justify-between ${openSidebar}`} style={{ width: `${sidebarWidth}px` }}>
        <div className='sidebar-inner'>


        <div className='d-flex flex-col align-center row-gap-15 mt-20 pb-20 border-b'>
          <div className="d-flex flex-col row-gap-15">
            <div>
              <button className="download-btn d-flex align-center p-10 col-gap-5" onClick={downloadLocalStorage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="7 10 12 15 17 10" /><line x1="12" x2="12" y1="15" y2="3" /></svg>
                Download My Data
              </button>
            </div>
          </div>

          <div className='more-info text-center'>To Backup Your Links</div>

          <div
            className='upload-file'
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
              border: '2px dashed #ccc',
              padding: '15px',
              borderRadius: '5px',
              textAlign: 'center',
              // marginTop: '20px',
            }}
          >

            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-up"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M12 12v6" /><path d="m15 15-3-3-3 3" /></svg>
            <p>Upload your saved data</p>
            <br />
            <p>Drag and drop your RamenLinks.json file here</p>
            <br />
            <p>This will overwrite any previously saved data</p>
            <br />
            <input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="fileUpload"
            />

            <label htmlFor="fileUpload" style={{ cursor: 'pointer', color: 'blue' }}>
              or click to select a file
            </label>
          </div>

          <div className="d-flex flex-col row-gap-15">
            <div>
              <button className="delete-all-data-btn sd-flex align-center p-10 col-gap-5 w-100 justify-center" onClick={deleteAllData}>
                <svg style={{ color: "black" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                Delete All My Data
              </button>
            </div>
          </div>
        </div>












          
          <div className='disclaimer mt-20'>
            {/* <div className="sidebar-grip" onMouseDown={handleMouseDown}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical"><circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" /></svg>
            </div> */}



            {/* <div key={"root"} className="permanent-folder d-flex">
              <Link to={`/`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>🏠 Home</h5></Link>
            </div>

            <div key={"home"} className="permanent-folder d-flex">
              <Link to={`/home`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>📓 Front Page</h5></Link>
              <button onClick={() => deleteFolder(homeFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div>

            <div key={"now"} className="permanent-folder now-folder d-flex">
              <Link to={`/now`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>⌚️ Now</h5></Link>
              <button onClick={() => deleteFolder(nowFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div>

            <div key={"today"} className="permanent-folder today-folder d-flex">
              <Link to={`/today`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>📆 Today</h5></Link>
              <button onClick={() => deleteFolder(todayFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div>

            <div key={"week"} className="permanent-folder week-folder d-flex">
              <Link to={`/thisweek`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>🗓️ This Week</h5></Link>
              <button onClick={() => deleteFolder(weekFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div> */}

            {/* <div key={"todo"} className="permanent-folder bookmark-folder d-flex col-gap-5">
              <div className='d-flex col-gap-5'>
                <div className='w-100' style={{ color: 'black', textDecoration: 'none' }}>
                  <h5>✔️ To Do Lists </h5>
                </div>
                <p style={{ fontSize: "11px", color: "grey", marginTop: "4px" }}>(Coming soon)</p>
              </div>

              <button onClick={() => deleteFolder(todoFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div> */}




            {/* <div key={"bookmarks"} className="permanent-folder todo-folder">
              <Link to={`/bookmarks`} className='w-100  d-flex align-center col-gap-5' style={{ color: 'black', textDecoration: 'none' }}>
                <h5>🔗 Top Links</h5>
              </Link>
            </div>

            <div key={"bookmarks"} className="permanent-folder todo-folder">
              <Link to={`/bookmarks`} className='w-100  d-flex align-center col-gap-5' style={{ color: 'black', textDecoration: 'none' }}>
                <h5>📸 Social Media</h5>
              </Link>
            </div>

            <div key={"bookmarks"} className="permanent-folder todo-folder">
              <Link to={`/bookmarks`} className='w-100  d-flex align-center col-gap-5' style={{ color: 'black', textDecoration: 'none' }}>
                <h5>🖥️ Streaming</h5>
              </Link>
            </div>

            <div key={"bookmarks"} className="permanent-folder todo-folder">
              <Link to={`/bookmarks`} className='w-100  d-flex align-center col-gap-5' style={{ color: 'black', textDecoration: 'none' }}>
                <h5>🧠 AI</h5>
              </Link>
            </div> */}





            <h3>❗️Disclaimer</h3>
            <br />
            <br />



            <h4>💾 Data is saved to your device </h4>
            <h4>(your browser's local storage)</h4>

            <br />
            <br />

            <h4>🗄️ Not A Server </h4>

            <br />
            <br />

            <h4>This means that if you...</h4>
            <h5>❌ Delete your browsing history</h5>
            <h5>❌ Clear your cache</h5>
            <h5>❌ or delete cookies</h5>
            <h5>You'll lose all your saved bookmarks❗️</h5>

            <br />
            <br />

            <h4>But you can... 😃</h4>

            <br />
            <br />

            <h4>💾 Download your data</h4>
            <h5>☝️ in this sidebar</h5>
            <h5>📄 As a file</h5>
            <h5>As a backup</h5>
            <h5>Think of it like saving a Microsoft Word file</h5>
            <h5>Then re-upload</h5>
            <h5>To any device</h5>

            <br />
            <br />

            <h4>Pros</h4>
            <h5>✅ No login</h5>
            <h5>✅ No Server</h5>
            <h5>✅ You own all your data</h5>
            <h5>✅ Each device is a blank slate</h5>
            <h5>✅ Create multiple workspaces with multiple files</h5>

            <br />
            <br />

            <h4>Cons</h4>
            <h5>❌ Need to save often</h5>
            <h5>❌ Difficult to use between devices</h5>
            <h5>❌ Data will dissapear if you clear your browser or use in incognito mode</h5>


            <br />
            <br />
            <br />
            <br />




            {/* <div key={"bookmarks"} className="permanent-folder todo-folder">
              <Link to={`/bookmarks`} className='w-100  d-flex align-center col-gap-5' style={{ color: 'black', textDecoration: 'none' }}>
                <h5>🔖 Bookmarks</h5>
              </Link>
            </div> */}

            {/* <div key={"chatgptprompts"} className="permanent-folder week-folder d-flex">
              <Link to={`/chatgptprompts`} className='w-100' style={{ color: 'black', textDecoration: 'none' }} onClick={closeSidebar}><h5>💬 Prompts</h5></Link>
              <button onClick={() => deleteFolder(chatgptpromptsFolderId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
              </button>
            </div> */}


            {/* <div key={"focus"} className="permanent-folder focus-folder d-flex">
                <Link to={`/focus`} className='w-100' style={{color: 'black', textDecoration: 'none'}}><h5>🎧 Focus</h5></Link>
                <button onClick={() => deleteFolder("focus")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                </button>
              </div> */}




            {/* <div className='d-flex justify-between align-center mt-20 pt-20 border-top'>
              <h5>📁 Folders</h5>
              <button className="add-folder-btn" onClick={addFolder}>+</button>
            </div> */}





            {/* <SortableContext items={folderOrder} strategy={verticalListSortingStrategy}>
              {folderOrder
                .filter((folderId) => folders[folderId] && folders[folderId].sidebar !== "permanent") // Exclude folders with sidebar="permanent"
                .map((folderId) => {
                  const folder = folders[folderId];
                  return (
                    <Folder key={folderId} id={folderId}>

                      <SidebarFolder
                        folderId={folderId}
                        toggleFolder={toggleFolder}
                        editingFolderId={editingFolderId}
                        setCurrentNote={setCurrentNote}
                        newFolderNameInput={newFolderNameInput}
                        setNewFolderNameInput={setNewFolderNameInput}
                        handleRenameFolder={handleRenameFolder}
                        folderOpen={folderOpen}
                        folder={folder}
                        togglePopup={togglePopup}
                        openMenu={openMenu}
                        popupRef={popupRef}
                        setEditingFolderId={setEditingFolderId}
                        setOpenMenu={setOpenMenu}
                        closeSidebar={closeSidebar}

                        deleteFolder={deleteFolder}
                        editingPageId={editingPageId}
                        editingFolderForPageId={editingFolderForPageId}
                        newPageNameInput={newPageNameInput}
                        setNewPageNameInput={setNewPageNameInput}
                        handleRenamePage={handleRenamePage}
                        togglePopupPage={togglePopupPage}
                        setEditingPageId={setEditingPageId}
                        setEditingFolderForPageId={setEditingFolderForPageId}
                        deletePage={deletePage}
                        addPage={addPage}
                        updateFolders={updateFolders}
                        folders={folders}
                      />

                    </Folder>
                  );
                })}
            </SortableContext> */}


            {/* 
            <div className="resize-handle" onMouseDown={handleMouseDown}>

            </div> */}
          </div>

        </div>
      </div>
    </DndContext>
  );
};

export default Sidebar;
