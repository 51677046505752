import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { v4 as uuidv4 } from 'uuid';
import '../styles/bookmarkspage.css';

const SortableItem = ({ id, link, deleteLink, updateLinks }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 10 : 1,
    };

    const saveToRecentlyUsed = () => {
        const storedData = JSON.parse(localStorage.getItem("RamenLinks")) || [];
        const recentlyUsedCategory = storedData.find(category => category.title === "Recently Used");
    
        if (recentlyUsedCategory) {
            // Create a new link object to add
            const newLink = {
                id: uuidv4(),
                name: link.name,
                url: link.url
            };
    
            // Check if the link already exists in Recently Used
            const linkExists = recentlyUsedCategory.links.find(item => item.url === newLink.url);
            if (linkExists) {
                // Move existing link to the top if it's already in Recently Used
                recentlyUsedCategory.links = recentlyUsedCategory.links.filter(item => item.url !== newLink.url);
            }
            
            // Add the new link to the top of the Recently Used links
            recentlyUsedCategory.links.unshift(newLink);
            
            // Update Local Storage
            localStorage.setItem("RamenLinks", JSON.stringify(storedData));

            // Immediately update the Recently Used links state in the component
            updateLinks("Recently Used", recentlyUsedCategory.links);
        }
    };

    return (
        <div ref={setNodeRef} style={style} className="link-item" {...attributes}>
            <div className="drag-handle" {...listeners}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="5" r="1" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="19" r="1" />
                    <circle cx="19" cy="5" r="1" />
                    <circle cx="19" cy="12" r="1" />
                    <circle cx="19" cy="19" r="1" />
                    <circle cx="5" cy="5" r="1" />
                    <circle cx="5" cy="12" r="1" />
                    <circle cx="5" cy="19" r="1" />
                </svg>
            </div>
            <a 
                href={link.url}
                target="_blank" 
                rel="noopener noreferrer" 
                className="link-text d-flex align-center col-gap-5"
                data-fulltext={link.name} 
                onClick={saveToRecentlyUsed}
            >
                {link.url.includes('ramenlinks.com') ? (
                    <span>🍜</span>
                ) : link.url.includes('google.com/travel/flights') ? (
                    <span>✈️</span>
                ) : link.url.includes('google.com/maps') ? (
                    <span>📍</span>
                ) : (
                    <img src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=512`} alt="default favicon" className="favicon" />
                )}

                {link.name}
            </a>
            <button onClick={() => deleteLink(id)} className="delete-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
        </div>
    );
};

const GenericTemplate = ({defaultLinks, title, handleDeleteSection, updateLinks}) => {
    const [links, setLinks] = useState(() => {
        const storedData = JSON.parse(localStorage.getItem("RamenLinks")) || {};
        return storedData[title]?.links || defaultLinks;
    });

    const [categoryTitle, setCategoryTitle] = useState(() => {
        const storedData = JSON.parse(localStorage.getItem("RamenLinks")) || {};
        return storedData[title]?.name || title;
    });

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [openForm, setOpenForm] = useState(false);

    const toggleOpenForm = () => {
        setOpenForm(!openForm);
    }

    // Save links and category title to localStorage whenever they change
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("RamenLinks")) || {};
        storedData[title] = { name: categoryTitle, links };
        localStorage.setItem("RamenLinks", JSON.stringify(storedData));
    }, [links, categoryTitle, title]);

    const handleTitleChange = (e) => {
        setCategoryTitle(e.target.value);
    };

    const handleTitleBlur = () => {
        setIsEditingTitle(false);
    };
    
    const addLink = (e) => {
        e.preventDefault();
        const newLink = { id: `link-${Date.now()}`, name, url };
        const updatedLinks = [newLink, ...links];
        
        setLinks(updatedLinks);
        setName("");  // Reset form fields
        setUrl("");
    
        // Update parent state with new links
        updateLinks(title, updatedLinks);
    };

    const deleteLink = (id) => {
        const updatedLinks = links.filter(link => link.id !== id);
        setLinks(updatedLinks);
        updateLinks(title, updatedLinks);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = links.findIndex(link => link.id === active.id);
            const newIndex = links.findIndex(link => link.id === over.id);
            const newLinks = arrayMove(links, oldIndex, newIndex);
            setLinks(newLinks);
            updateLinks(title, newLinks);
        }
    };

    return (
        <div className="links-grid-container">
            <div className="header">
            {isEditingTitle ? (
                    <div className='header-inner d-flex justify-between'>
                    <div className='d-flex'>
                        <input
                            type="text"
                            value={categoryTitle}
                            onChange={handleTitleChange}
                            onBlur={handleTitleBlur}
                            autoFocus
                            className="edit-title-input"
                        />
                        <div className='open-link' onClick={toggleOpenForm}><h3>{openForm ? '-' : '+'}</h3><div className='open-link-text'>Add Link</div></div>
                    </div>
                    </div>
                ) : (
                    <div className='header-inner d-flex justify-between'>
                        <div className='d-flex'>

                        {categoryTitle === 'Recently Used' ? 
                            <div className='d-flex flex-col'>
                            <div><h3 onClick={() => setIsEditingTitle(true)}>{categoryTitle}</h3></div>
                            <div className='updates-automatically-text'>Updates Automatically</div>
                            </div>
                            :
                            <div><h3 onClick={() => setIsEditingTitle(true)}>{categoryTitle}</h3></div>
                        }

                            <div className='open-link' onClick={toggleOpenForm}><h3>{openForm ? '-' : '+'}</h3><div className='open-link-text'>Add Link</div></div>
                            <form onSubmit={addLink} className={`add-link-form desktop-only ${openForm ? '' : 'd-none'}`}>
                                <input 
                                    type="text" 
                                    value={name} 
                                    onChange={(e) => setName(e.target.value)} 
                                    placeholder="Link Name" 
                                    required 
                                />
                                <input 
                                    type="url" 
                                    value={url} 
                                    onChange={(e) => setUrl(e.target.value)} 
                                    placeholder="Link URL" 
                                    required 
                                />
                                <button type="submit">Add Link</button>
                            </form>
                        </div>
                    </div>
                )}
                    <form onSubmit={addLink} className={`add-link-form mobile-tablet-only ${openForm ? '' : 'd-none'}`}>
                        <input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            placeholder="Link Name" 
                            required 
                        />
                        <input 
                            type="url" 
                            value={url} 
                            onChange={(e) => setUrl(e.target.value)} 
                            placeholder="Link URL" 
                            required 
                        />
                        <button type="submit">Add Link</button>
                    </form>
                    {categoryTitle !== 'Recently Used' &&
                        <div className='d-flex align-center col-gap-5 justify-end'>
                            <button className="section-delete-button desktop-only" onClick={() => handleDeleteSection(title)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                                Delete Section
                            </button>
                        </div>
                    }
            </div>
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToWindowEdges, restrictToParentElement]}
            >
                <SortableContext items={links.map(link => link.id)} strategy={rectSortingStrategy}>
                    {/* <div className='card'> */}
                    <div className="links-grid">
                        {links.map(link => (
                            <SortableItem key={link.id} id={link.id} link={link} updateLinks={updateLinks} deleteLink={deleteLink} />
                        ))}
                    </div>
                    {/* </div> */}
                </SortableContext>
            </DndContext>
            {categoryTitle !== 'Recently Used' &&
                <button className="section-delete-button mobile-tablet-only" onClick={() => handleDeleteSection(title)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                    Delete Section
                </button>
            }
        </div>
    );
};

export default GenericTemplate;
